<script setup>
/* Imports */
import { computed } from 'vue';

/* Helpers */
import { replaceSuperScript } from '../helpers/mainHelpers';

/* Props */
const props = defineProps({
  content: {
    type: String,
    default: '',
  },
});

const computedContent = computed(() => replaceSuperScript(props.content));
</script>

<template>
  <div
    id="js__product__availability"
    class="app__product__availability"
    v-html="computedContent"
  />
</template>

<style lang="scss">
.app__product__availability {

  img {
    vertical-align: middle;
  }

  p {
    margin: 0;
  }
}
</style>
