<script>
/* Imports */
import { mapActions, mapGetters } from 'vuex';

/* Analytics */
import {
  sendAnalyticsEvent,
  sanitizeAnalyticsString,
  getCategory3Data,
  getSelectedEmsOption,
  getSelectedEmsOptionIndex,
  getAnalyticsPriceData,
} from '../helpers/ga/analytics';

/* Helpers */
import { request, convertCartConversionQueryParamsToString } from '../helpers/mainHelpers';

/* Composables */
import { useGenericAnalyticsDataHelper } from '../composables/useAnalyticsData';

/* Components */
import ProductWarning from '../components/ProductWarning.vue';

export default {
  name: 'OldCtasWrapper',
  components: {
    ProductWarning,
  },
  props: {
    showAddToCartBtn: {
      type: Boolean,
      default: false,
    },
    addToCartLoading: {
      type: Boolean,
      default: false,
    },
    setAddToCartLoading: {
      type: Function,
      default: () => null,
    },
    addToCartHref: {
      type: String,
      default: '',
    },
    addToCartText: {
      type: String,
      default: '',
    },
    showDealerBtn: {
      type: Boolean,
      default: false,
    },
    dealerHref: {
      type: String,
      default: '',
    },
    dealerText: {
      type: String,
      default: '',
    },
    expressWarning: {
      type: String,
      default: '',
    },
    showExpressWarning: {
      type: Boolean,
      default: true,
    },
    currencyCode: {
      type: String,
      default: '',
    },
  },
  setup() {
    /* Initialize Composables */
    const { genericAnalyticsData } = useGenericAnalyticsDataHelper();
    return { genericAnalyticsData };
  },
  computed: {
    ...mapGetters({
      locale: 'getLocale',
      productName: 'getProductName',
      globalProductName: 'getGlobalProductName',
      productId: 'getProductId',
      priceObj: 'getPriceData',
      seoAttributes: 'getSeoAttributes',
      marketCategoryGlobal: 'getMarketCategoryGlobal',
      productCategoryGlobal: 'getProductCategoryGlobal',
      productVariation: 'getProductVariation',
      galleryImageList: 'getGalleryImageList',
      interstitialErrorMessage: 'getInterstitialApiErrorMessage',
      partNumber: 'getPartNumber',
      productPageApi: 'getProductPageApi',
      bundleButton: 'getPvProductEcommerceOpenText',
      optionsList: 'getOptionsList',
      optionListByType: 'getOptionListByType',
      sellable: 'getSellable',
    }),
    loadItemClass() {
      return this.addToCartLoading ? 'app__product__cta__loading' : '';
    },
    computedAddToCartHref() {
      const queryParams = this.$route?.query;
      const stringifiedCartConversionQueryParams = convertCartConversionQueryParamsToString(queryParams);
      return stringifiedCartConversionQueryParams ? `${this.addToCartHref}${stringifiedCartConversionQueryParams}` : this.addToCartHref;
    },
  },
  methods: {
    ...mapActions(['setInterstitialIsVisible', 'setInterstitialProductDetails']),
    redirectToCart(url) {
      window.location.assign(url);
    },
    async getCart() {
      return request({
        url: `${this.productPageApi}/cart`,
        method: 'POST',
      });
    },
    async addToCart(sku) {
      return request({
        url: `${this.productPageApi}/addToCart/${sku}`,
        method: 'POST',
      });
    },
    async handleAddToCart(event) {
      const url = event.target.href;

      event.preventDefault();

      try {
        this.setAddToCartLoading(true);

        // Make sure that we have a valid cart
        await this.getCart();

        // Add the bundle to cart
        await this.addToCart(this.partNumber);

        // Trigger update for the cart count in the header
        this.updateCartCount();

        // Show interstitial window
        this.setInterstitialIsVisible(true);
        this.setInterstitialProductDetails({
          name: this.productName,
          variation: this.productVariation,
          imageSrc: this.galleryImageList?.length && this.galleryImageList[0]?.imageSrc,
          altText: this.galleryImageList?.length && this.galleryImageList[0]?.altText,
        });

        this.setAddToCartLoading(false);
      } catch (e) {
        // If we have an error, redirect to the cart
        this.redirectToCart(url);
      }
    },
    updateCartCount() {
      const event = document.createEvent('Event');
      event.initEvent('cartCountChanged', true, false);
      document.dispatchEvent(event);
    },
    addToCartBtnClick(event) {
      this.handleAddToCart(event);
      this.addToCartUTagEvent();
    },
    getAnalyticsProductName() {
      return sanitizeAnalyticsString(this.globalProductName) ?? 'NA';
    },
    getCategory3Value() {
      const productName = this.getAnalyticsProductName();
      return getCategory3Data(productName);
    },
    sendCustomAnalyticsData(customAnalyticsData) {
      const analyticsData = {
        event_action: 'click',
        event_category: `Product Page >> ${sanitizeAnalyticsString(this.globalProductName)}`,
        index: getSelectedEmsOptionIndex(this.optionListByType('image')),
        link_type: 'button',
        product_brand: window?.utag_data?.product_brand || ['NA'],
        product_category: window?.utag_data?.product_category || ['NA'],
        product_category2: window?.utag_data?.product_category2 || ['NA'],
        product_category3: this.getCategory3Value(),
        product_category4: getSelectedEmsOption(this.optionsList, 0),
        product_category5: getSelectedEmsOption(this.optionsList, 1),
        product_id: this.productId ? [this.productId] : ['NA'],
        product_name: [this.getAnalyticsProductName()],
        product_original_price: getAnalyticsPriceData(this).listPrice,
        product_current_price: getAnalyticsPriceData(this).price,
        product_sku: this.partNumber ? [this.partNumber] : ['NA'],
        product_url: window?.location?.href ? [window.location.href.split('?')[0]] : ['NA'],
        product_variant: this.productVariation ? [sanitizeAnalyticsString(this.productVariation)] : ['NA'],
      };

      const combinedAnalyticsData = { ...analyticsData, ...customAnalyticsData };

      // transmit the event
      sendAnalyticsEvent(combinedAnalyticsData);
    },
    addToCartUTagEvent() {
      const analyticsData = {
        // Send these as undefined since we're going to work in another context to update these values
        ...this.genericAnalyticsData(
          undefined,
          undefined,
        ),
        index: getSelectedEmsOptionIndex(this.optionListByType('image')),
        product_id: [this.productId || 'NA'],
        product_brand: window?.utag_data?.product_brand[0] || 'NA',
        product_category: window?.utag_data?.product_category || 'NA',
        product_category2: window?.utag_data?.product_category2 || 'NA',
        product_category3: this.getCategory3Value(),
        product_category4: getSelectedEmsOption(this.optionsList, 0),
        product_category5: getSelectedEmsOption(this.optionsList, 1),
        product_variant: [sanitizeAnalyticsString(this.productVariation) || 'NA'],
        product_name: [sanitizeAnalyticsString(this.globalProductName) || 'NA'],
        product_original_price: getAnalyticsPriceData(this).listPrice,
        product_price: getAnalyticsPriceData(this).price,
        product_sku: this.partNumber || 'NA',
        product_url: window?.location?.href?.split('?')?.[0] || 'NA',
        product_availability: getAnalyticsPriceData(this)?.availability,
        product_quantity: '1',
        item_family: [this.getCategory3Value()?.[0] || 'NA'],
        is_sale: getAnalyticsPriceData(this)?.isSale,
        in_stock: getAnalyticsPriceData(this)?.inStock,
        price_shown: getAnalyticsPriceData(this)?.priceShown,
        // Send the following fields as 'NA' for now since they are not available in the current context
        creative_name: 'NA',
        creative_slot: 'NA',
        location_id: 'NA',
        promotion_name: 'NA',
        promotion_id: 'NA',
        bundle_id: 'NA',
        is_bundle: 'NA',
        sale_type: 'NA',
        tealium_event: 'add_to_cart',
        event_label: 'Add to Cart',
      };

      sendAnalyticsEvent(analyticsData);
    },
    addToCartBundleBtnClick() {
      this.sendCustomAnalyticsData({
        tealium_event: 'add_to_cart',
        event_label: 'Custom Bundle Add to Cart',
      });
    },
    findADealerBtnClick() {
      sendAnalyticsEvent({
        // Send these as undefined since we're going to work in another context to update these values
        ...this.genericAnalyticsData(
          undefined,
          undefined,
        ),
        tealium_event: 'find_dealer',
        event_label: 'Find Dealer',
        link_type: 'button',
        product_id: this.productId,
        product_name: sanitizeAnalyticsString(this.globalProductName),
        product_variant: sanitizeAnalyticsString(this.productVariation),
        event_category: `Product Page >> ${sanitizeAnalyticsString(this.globalProductName)}`,
        event_action: 'click',
        product_price: getAnalyticsPriceData(this).listPrice, // price without discount
        product_sku: this.partNumber,
        discount: this.priceObj?.savings?.price ?? 'NA',
        promotion: 'no',
        product_family: this.seoAttributes?.attributeGroupName ?? 'NA',
      });
    },
  },
};
</script>

<template>
  <div
    id="js__product__ctas"
    class="app__product__cta"
  >
    <ProductWarning
      v-if="showExpressWarning"
      id="js__cta__warning"
      :content="expressWarning"
    />
    <div>
      <a
        v-if="showAddToCartBtn"
        id="js__cta__buy"
        :href="computedAddToCartHref"
        :class="['app__product__cta__button', 'app__product__cta__button__buy', loadItemClass]"
        @click="addToCartBtnClick($event)"
      >
        {{ addToCartText }}
      </a>
      <a
        v-if="showDealerBtn"
        id="js__cta__dealer"
        :href="dealerHref"
        class="app__product__cta__button app__product__cta__button__dealer"
        @click="findADealerBtnClick"
      >
        {{ dealerText }}
      </a>

      <span
        @click="addToCartBundleBtnClick"
        v-html="bundleButton"
      />
    </div>
  </div>
</template>

<style lang="scss">
.old__app__product__info {

  .app__product__cta {
    @include productBreakpoint(desktop-s) {
      max-width: 570px;
    }

    .app__product__cta__button {
      position: relative;
      display: inline-block;
      padding: 1em 3em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-size: 0.75em;
      font-weight: 700;
      border: none;
      /* stylelint-disable-next-line */
      transition: background 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      cursor: pointer;
      margin: 1em 0.4em 1em auto;
      max-width: 80%;

      &:hover {
        text-decoration: none;
      }

      &:last-of-type {
        margin: 1em auto 1em auto;
      }

      &.app__product__cta__loading {
        cursor: wait;

        &::after {
          content: '';
          display: block;
          background-color: rgba($color-white, 0.8);
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          cursor: wait;
        }
      }
      @include breakpoint(sm) {
        position: relative;
        width: auto;
        text-align: center;
        max-width: none;
      }
      @include productBreakpoint(desktop-s) {
        text-align: left;

        &:first-child {
          margin-right: 0.4em;
        }
      }

      &__buy {
        background: $blue;
        color: $color-white;
        @include breakpoint(sm) {
          width: auto;
        }

        &:hover {
          background: lighten($blue, 5%);
          color: $color-white;
        }

        &:visited {
          color: $color-white;
        }
      }

      &__dealer {
        color: $blue;
        background: $color-white;
        box-shadow: inset 0 0 0 2px $blue;
        @include breakpoint(sm) {
          display: inline-block;
        }

        &:hover {
          box-shadow: inset 0 0 0 2px lighten($blue, 5%);
          background: darken($color-white, 5%);
          color: lighten($blue, 5%);
        }

        &:visited {
          color: $blue;
        }
      }
    }
  }
}
</style>
