<script setup>
/* Imports */
import { computed } from 'vue';

/* Helpers */
import { replaceSuperScript } from '../helpers/mainHelpers';

/* Props */
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  tooltipText: {
    type: String,
    default: '',
  },
});

const computedTooltipText = computed(() => replaceSuperScript(props.tooltipText));
const computedTitle = computed(() => replaceSuperScript(props.title));
</script>

<template>
  <div class="app__tooltip">
    <!-- eslint-disable vue/no-v-text-v-html-on-component -->
    <g-tooltip
      :tooltip-text="computedTooltipText"
      v-html="computedTitle"
    />
    <!-- eslint-enable vue/no-v-text-v-html-on-component -->
  </div>
</template>

<style lang="scss" scoped>
.app__tooltip {
  font-size: 0.75em;
  margin-bottom: 0.4em;

  @include productBreakpoint('desktop-s') {
    max-width: 400px;
  }
}
</style>
