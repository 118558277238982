<script>
/* Imports */
import { mapActions, mapGetters } from 'vuex';

/* Analytics */
import {
  sendAnalyticsEvent,
  sanitizeAnalyticsString,
  getCategory3Data,
  getSelectedEmsOption,
  getSelectedEmsOptionIndex,
  getAnalyticsPriceData,
} from '../helpers/ga/analytics';

/* Helpers */
import { getWwwUrl } from '../helpers/mainHelpers';

/* Composables */
import { useGenericAnalyticsDataHelper } from '../composables/useAnalyticsData';

/* Old Components */
import OldTooltip from './OldTooltip.vue';

export default {
  name: 'OldColorPicker',
  components: {
    OldTooltip,
  },
  props: {
    currencyCode: {
      type: String,
      default: '',
    },
  },
  setup() {
    /* Initialize Composables */
    const { genericAnalyticsData } = useGenericAnalyticsDataHelper();
    return { genericAnalyticsData };
  },
  data() {
    return {
      preloadedSkus: [],
    };
  },
  computed: {
    ...mapGetters([
      'getOptionListByType',
      'getAllOptionItems',
      'getProductId',
      'getGlobalProductName',
      'getProductVariation',
      'getOptions',
      'getOptionsList',
      'getPriceData',
      'getSeoAttributes',
      'getProductBySku',
      'getPartNumber',
      'getWwwGarminEndpoint',
      'getBuyGarminEndpoint',
    ]),
    optionsList() {
      return this.getOptionListByType('image');
    },
  },
  methods: {
    ...mapActions(['updateProductData', 'setProductActiveTabId']),
    getImages(imageCategory) {
      return this.getAllOptionItems(imageCategory);
    },
    calculateLinkClass(image) {
      return {
        app__color__picker__link__active: image.enabled,
        js__active: image.enabled,
        app__color__picker__link__selected: image.selected,
        js__selected: image.selected,
      };
    },
    getUrl(url) {
      return getWwwUrl(url, this.getBuyGarminEndpoint, this.getWwwGarminEndpoint);
    },
    getAnalyticsProductName() {
      return sanitizeAnalyticsString(this.getGlobalProductName) || 'NA';
    },
    getCategory3Value() {
      const productName = this.getAnalyticsProductName();
      return getCategory3Data(productName);
    },
    sendColorPickerAnalyticsEvent(item) {
      if (!item) return;

      const productPrice = {
        priceObj: this.getPriceData,
        sellable: this.getProductBySku(item?.sku)?.isSellable,
        showPrice: this.getProductBySku(item?.sku)?.showPrice,
      };

      const analyticsData = {
        ...this.genericAnalyticsData(
          'Product Page EMS',
          'Product Page EMS - Thumbnail',
        ),
        // Send the following fields as 'NA' for now since they are not available in the current context
        creative_name: 'NA',
        creative_slot: 'NA',
        location_id: 'NA',
        promotion_name: 'NA',
        promotion_id: 'NA',
        bundle_id: 'NA',
        is_bundle: 'NA',
        sale_type: 'NA',
        index: getSelectedEmsOptionIndex(this.optionsList),
        product_id: this.getProductId ? [this.getProductId] : ['NA'],
        product_brand: window?.utag_data?.product_brand || ['NA'],
        product_category: window?.utag_data?.product_category || ['NA'],
        product_category2: window?.utag_data?.product_category2 || ['NA'],
        product_category3: this.getCategory3Value(),
        product_category4: getSelectedEmsOption(this.getOptionsList, 0),
        product_category5: getSelectedEmsOption(this.getOptionsList, 1),
        product_variant: this.getProductVariation ? [sanitizeAnalyticsString(this.getProductVariation)] : ['NA'],
        product_name: [this.getAnalyticsProductName()],
        product_original_price: [getAnalyticsPriceData(productPrice).listPrice],
        product_price: [getAnalyticsPriceData(productPrice).price],
        product_sku: item?.sku ? [item.sku] : ['NA'],
        product_url: [item?.url || 'NA'],
        product_availability: [getAnalyticsPriceData(productPrice).availability],
        item_family: this.getCategory3Value(),
        is_sale: getAnalyticsPriceData(productPrice).isSale,
        in_stock: getAnalyticsPriceData(productPrice).inStock,
        price_shown: getAnalyticsPriceData(productPrice).priceShown,
        link_type: ['EMS image'],
        tealium_event: 'select_item',
      };

      sendAnalyticsEvent(analyticsData);
    },
    async changeColor(item) {
      // if we are already displaying this sku, don't update the product data
      if (item.sku === this.getPartNumber) {
        return;
      }

      // update the product data
      await this.updateProductData(item.sku);

      // send analytics event
      this.sendColorPickerAnalyticsEvent(item);
    },
    preloadImages(item) {
      // If we already preloaded it or is the current product do nothing
      if (this.preloadedSkus[item.sku] || this.getPartNumber === item.sku) return;

      const productDetails = this.getProductBySku(item.sku);

      // In case the SKU is not available simply return
      if (!productDetails) return;

      // We want the default image and the rest of the gallery
      const images = [{ ...productDetails.images.defaultImage }, ...(productDetails.images.gallery || [])];

      // Do the actual preloading
      images.forEach((imgObj) => {
        const imgSrc = imgObj.image || imgObj.imageSrc;
        if (!imgSrc) return;
        const img = new Image();
        img.src = imgSrc;
      });

      // Add the SKU to preloaded ones
      this.preloadedSkus[item.sku] = true;
    },
  },
};
</script>

<template>
  <div class="app__color__picker js__filters-wrapper">
    <div
      v-for="option in optionsList"
      :key="option.id"
    >
      <div class="app__color__picker__title">
        <OldTooltip
          :title="option.title"
          tooltip-text="undefined"
        />
      </div>
      <ul class="app__color__picker__list">
        <li
          v-for="item in getImages(option.id)"
          :key="item.sku"
          class="app__color__picker__item"
        >
          <a
            class="app__color__picker__link js__filter-option"
            :class="calculateLinkClass(item)"
            :href="getUrl(item.url)"
            @click.prevent="changeColor(item)"
            @mouseover="preloadImages(item)"
          >
            <img
              :src="item.image"
              :alt="item.altText"
            >
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app__color__picker {

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    @include productBreakpoint(desktop-s) {
      max-width: 570px;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__item {
    text-transform: uppercase;
    display: block;
    font-size: 0.9em;
    margin: 0 0.6em 0.6em 0;

    img {
      width: 60px;
      height: 60px;
      overflow: hidden;
      opacity: 0.3;
      vertical-align: middle;

      @include breakpoint(sm) {
        width: 80px;
        height: 80px;
      }
    }
  }

  &__link {
    display: block;
    padding: 0;
    border: 2px solid $gray-medium-light;
    transition: background-color 0.5s, border-color 0.5s, color 0.5s;

    &:hover {
      border-color: $color-black;
    }

    &__active,
    &__selected {

      img {
        transition: opacity 0.5s;
        opacity: 1;
      }
    }

    &__selected {
      border-color: $color-black;
    }
  }
}
</style>
