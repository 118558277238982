/* Imports */
import router from '../router';

/* Helpers */
import {
  request,
  templateBuilder,
  priceTemplateBuilder,
} from '../helpers/mainHelpers';

const getTabIdfromHash = () => router.currentRoute.value?.hash.replace('#', '');

export default {
  state: {
    tabs: [],
    activeTabId: '',
    compatibleDevices: {},
    devicesTabTitle: '',
    devicesLoading: true,
    newOverviewTab: false,
    overviewContainsDisclaimers: false,
    accessoriesLoading: true,
    accessoriesData: {},
    accessoriesByCategory: {},
    accessoryPrices: {},
    categoryNames: [],
    globalCategoryNames: [],
    categoriesListCollapsed: false,
    showSections: {},
    activeSection: null,
    viewAllSections: false,
    showNoAccessoriesText: false,
    errorAccessoriesText: false,
    showNoDevicesText: false,
    errorDevicesText: false,
    sectionAccessoriesPricesLoaded: false,
  },
  getters: {
    getProductTabs(state) {
      return state.tabs;
    },
    getProductTabsAreSet(state, getters) {
      return !!getters.getProductTabs.length;
    },
    getActiveProductTabs(state) {
      return state.tabs.filter((tab) => !tab.hide);
    },
    getDefaultProductTab(state, getters) {
      return getters.getActiveProductTabs[0];
    },
    getIsActiveProductTab(state, getters) {
      return (tabId) => !!getters.getActiveProductTabs.find((tab) => tab.id === tabId);
    },
    getProductTab(state) {
      return (key) => state.tabs.find((tab) => tab.id === key);
    },
    getOverviewContainsDisclaimers(state) {
      const content = state.tabs?.find((tab) => tab.id === 'overview')?.content;
      // contains either <pc-disclaimer-block> component or `disclaimer-block` class
      return content?.includes('disclaimer-block');
    },
    getHashIsValidProductTab(state, getters) {
      const currentTabId = getTabIdfromHash();

      return !!getters.getProductTab(currentTabId);
    },
    getProductActiveTabId(state) {
      return state.activeTabId;
    },
    getActiveProductTab(state) {
      return state.tabs.find((tab) => tab.id === state.activeTabId);
    },
    getAccessoriesData(state) {
      return state.accessoriesData;
    },
    getAccessoriesByPartNumber(state, getters) {
      return (partNumber) => getters.getAccessoriesData[partNumber];
    },
    getActiveAccessories(state, getters) {
      const partNumber = getters.getPartNumber;
      const accessories = getters.getAccessoriesByPartNumber(partNumber);
      return accessories || [];
    },
    getActiveAccessoriesByCategory(state, getters) {
      const accessories = getters.getActiveAccessories;
      return (categoryName) => accessories.filter((acc) => acc.categoryName === categoryName);
    },
    getCategoryImageByCategory(state, getters) {
      const accs = getters.getActiveAccessories;
      return (categoryName) => accs.filter((acc) => acc.categoryName === categoryName)?.[0]?.image;
    },
    getAccessoryPrices(state) {
      return state.accessoryPrices;
    },
    getAccessoryPriceByPriceUrl(state, getters) {
      return (priceUrl) => getters.getAccessoryPrices[priceUrl];
    },
    getCompatibleDevices(state) {
      return state.compatibleDevices;
    },
    getDevicesTabTitle(state) {
      return state.devicesTabTitle;
    },
    getCompatibleDevicesByPartNum(state, getters) {
      return (partNum) => getters.getCompatibleDevices[partNum];
    },
    getActiveCompatibleDevices(state, getters) {
      const partNum = getters.getPartNumber;
      const devices = getters.getCompatibleDevicesByPartNum(partNum);
      return devices || [];
    },
    getDevicesLoading(state) {
      return state.devicesLoading;
    },
    getAccessoriesTabTitle(state, getters) {
      return getters.getProductTab('accessories')?.title;
    },
    getAccessoriesLoading(state) {
      return state.accessoriesLoading;
    },
    getNewOverviewTab(state) {
      return state.newOverviewTab;
    },
    getCategoryNames(state) {
      return state.categoryNames;
    },
    getGlobalCategoryNames(state) {
      return state.globalCategoryNames;
    },
    getShowSections(state) {
      return state.showSections;
    },
    getShowNoAccessoriesText(state) {
      return state.showNoAccessoriesText;
    },
    getErrorAccessoriesText(state) {
      return state.errorAccessoriesText;
    },
    getActiveSection(state) {
      return state.activeSection;
    },
    getViewAllSections(state) {
      return state.viewAllSections;
    },
    getShowNoDevicesText(state) {
      return state.showNoDevicesText;
    },
    getErrorDevicesText(state) {
      return state.errorDevicesText;
    },
    getCategoriesListCollapsed(state) {
      return state.categoriesListCollapsed;
    },
    getSectionAccessoriesPricesLoaded(state) {
      return state.sectionAccessoriesPricesLoaded;
    },
  },
  mutations: {
    setProductTabs(state, payload) {
      state.tabs = payload;
    },
    setShowProductTab(state, payload) {
      const { tabs, tabId } = payload;
      const targetTab = tabs.find((tab) => tabId === tab.id);
      targetTab.hide = false;

      state.tabs = tabs;
    },
    setProductActiveTabId(state, payload) {
      state.activeTabId = payload;
    },
    setCompatibleDevice(state, payload) {
      const { data, partNum } = payload;
      state.compatibleDevices = { ...state.compatibleDevices, [partNum]: data };
    },
    setDevicesLoading(state, payload) {
      state.devicesLoading = payload;
    },
    setDevicesTabTitle(state, payload) {
      state.devicesTabTitle = payload;
    },
    setAccessoriesLoading(state, payload) {
      state.accessoriesLoading = payload;
    },
    setNewOverviewTab(state, payload) {
      state.newOverviewTab = payload;
    },
    setAccessories(state, payload) {
      const { data, partNumber } = payload;
      state.accessoriesData = { ...state.accessoriesData, [partNumber]: data };
    },
    setAccessoryPrice(state, payload) {
      const { data, priceUrl } = payload;
      state.accessoryPrices = { ...state.accessoryPrices, [priceUrl]: data };
    },
    setCategoryNames(state, payload) {
      state.categoryNames = payload;
    },
    setGlobalCategoryNames(state, payload) {
      state.globalCategoryNames = payload;
    },
    setShowSections(state, payload) {
      state.showSections = payload;
    },
    setShowNoAccessoriesText(state, payload) {
      state.showNoAccessoriesText = payload;
    },
    setErrorAccessoriesText(state, payload) {
      state.errorAccessoriesText = payload;
    },
    setActiveSection(state, payload) {
      state.activeSection = payload;
    },
    setViewAllSections(state, payload) {
      state.viewAllSections = payload;
    },
    setShowNoDevicesText(state, payload) {
      state.showNoDevicesText = payload;
    },
    setErrorDevicesText(state, payload) {
      state.errorDevicesText = payload;
    },
    setCategoriesListCollapsed(state, payload) {
      state.categoriesListCollapsed = payload;
    },
    setSectionAccessoriesPricesLoaded(state, payload) {
      state.sectionAccessoriesPricesLoaded = payload;
    },
  },
  actions: {
    setProductTabs({ dispatch }, tabs) {
      const presetTab = window.location.hash.substr(1);
      const hasPresetTab = !!presetTab;
      const tabSettings = {
        tabsPayload: tabs,
        presetTab,
        setHash: hasPresetTab,
      };

      dispatch('updateProductTabs', tabSettings);
    },
    async updateProductTabs({ commit, dispatch, getters }, tabSettings) {
      const showCompatibleDevicesTab = getters.getShowCompatibleDevicesTab;
      const devicesTabTitle = getters.getDevicesTabTitle;
      const { presetTab, tabsPayload, setHash } = tabSettings;

      // This tab list enforces tab order
      const possibleTabs = [
        {
          id: 'overview',
          name: 'overviewTab',
        },
        {
          id: 'specs',
          name: 'specsTab',
        },
        {
          id: 'inTheBox',
          name: 'inTheBoxTab',
        },
        {
          id: 'additional',
          name: 'additionalTab',
        },
        {
          id: 'maps',
          name: 'mapsTab',
          // added line to enfore to load the new Maps vue app
          content: tabsPayload.mapsTab !== undefined ? '<Maps />' : null,
        },
        {
          id: 'requirements',
          name: 'requirementsTab',
        },
        {
          id: 'features',
          name: 'featuresTab',
        },
        {
          id: 'cycles',
          name: 'cyclesTab',
        },
        {
          id: 'coverage',
          name: 'coverageTab',
        },
        {
          id: 'accessories',
          name: 'accessoriesTab',
          content: tabsPayload.accessoriesTab !== undefined ? '<Accessories />' : null,
        },
        {
          id: 'devices',
          name: 'devicesTab',
          title: devicesTabTitle,
          content: showCompatibleDevicesTab ? '<CompatibleDevices />' : null,
          hide: true,
        },
      ];

      const tabs = possibleTabs.reduce((tabList, tab) => {
        const { name } = tab;
        const tabData = { ...tabsPayload[name] };

        const newTab = {
          ...tabData,
          ...tab, // last one gets priority if duplicate props
        };

        // if the new tab doesn't have content, don't add it to the tab list array
        if (newTab.content) tabList.push(newTab);

        return tabList;
      }, []);

      commit('setProductTabs', tabs);
      if (showCompatibleDevicesTab) {
        await dispatch('callCompatibleDevices');
      }

      // filter out hidden tabs
      const filteredTabs = getters.getActiveProductTabs;
      if (filteredTabs && filteredTabs.length) {
        const defaultTab = filteredTabs.find((tab) => tab.id === presetTab);
        // if there isn't a hash already set, set the active tab to the first tab
        const activeTab = defaultTab ? defaultTab.id : getters.getDefaultProductTab.id;
        // if there isn't a hash already set (the user didn't select a tab),
        // don't set the hash because we'll just default to the first tab
        // We should only set the hash if the user selected a tab
        const shouldSetHash = defaultTab ? setHash : false;
        const tabPayload = {
          tabId: activeTab,
          setHash: shouldSetHash,
        };

        dispatch('setProductActiveTabId', tabPayload);
        // if the hash should not be set but the tab is being updated, clear the hash in the route
        if (!shouldSetHash) dispatch('clearRouteTabHash');
      }
    },
    setProductActiveTabId({ commit, dispatch }, payload) {
      const { tabId, setHash } = payload;
      const setRouteTabHash = setHash === undefined ? true : setHash;

      commit('setProductActiveTabId', tabId);
      if (setRouteTabHash) dispatch('setRouteTabHash', tabId);
    },
    setRouteTabHash({ dispatch }, tabId) {
      const currentHash = getTabIdfromHash();
      const newHash = `#${tabId}`;

      if (tabId !== currentHash) dispatch('setTabRoute', newHash);
    },
    clearRouteTabHash({ dispatch }) {
      const { hash } = router.currentRoute.value;

      if (hash) dispatch('setTabRoute', '');
    },
    setTabRoute(context, hash) {
      const { path, query } = router.currentRoute.value;

      router.push({ path, hash, query });
    },
    async callCompatibleDevices({ commit, dispatch, getters }) {
      const devices = getters.getCompatibleDevices;
      const partNum = getters.getPartNumber;
      const urlTemplate = getters.getDevicesJsonUrl;
      let data = devices[partNum];

      commit('setDevicesLoading', true);

      if (urlTemplate && !data) {
        const url = templateBuilder(urlTemplate, { partNum });
        data = await request({
          url,
        }).catch((error) => {
          commit('setErrorDevicesText', true);
          throw new Error(`Unable to call compatibleDevices service: ${error}`);
        });

        if (!data || !data.length || !Array.isArray(data)) {
          commit('setDevicesLoading', false);
          commit('setShowNoDevicesText', true);
          return;
        }
      }

      const device = {
        partNum,
        data,
      };
      commit('setCompatibleDevice', device);
      commit('setDevicesLoading', false);
      dispatch('setShowProductTab', 'devices');

      // this is here so that if you load the page with #devices hash,
      // it will select it once it unhides the tab and finishes loading.
      /* eslint-disable no-restricted-globals */
      if (location.hash && location.hash === '#devices') {
        commit('setProductActiveTabId', 'devices');
      }
      /* eslint-enable no-restricted-globals */
    },
    setShowProductTab({ commit, getters }, tabId) {
      const tabs = getters.getProductTabs;
      const payload = {
        tabs,
        tabId,
      };
      commit('setShowProductTab', payload);
    },
    async callAccessories({ commit, getters }) {
      const accessoriesData = getters.getAccessoriesData;
      const activeTabId = getters.getProductActiveTabId;
      const sku = getters.getPartNumber;
      const urlTemplate = getters.getAccessoriesJsonUrl;
      // `url` contains the endpoint to call for accessories
      const url = templateBuilder(urlTemplate, { sku });
      const accessoriesSelected = activeTabId === 'accessories';

      // if accessories tab isn't selected,
      // exit out of this call so accessories isn't called unnecessarily along with pricing
      if (!accessoriesSelected) return;

      commit('setAccessoriesLoading', true);

      let data;

      if (!urlTemplate || accessoriesData[sku]) {
        data = getters.getActiveAccessories;
      } else {
        data = await request({
          url,
        }).catch(() => {
          commit('setAccessoriesLoading', false);
          commit('setErrorAccessoriesText', true);
          throw new Error('Unable to call Accessories service');
        });
        if (!data || data.length === 0) {
          commit('setShowNoAccessoriesText', true);
        }
      }

      const categorySet = new Set();
      const globalCategorySet = new Set();

      const showSectionsArray = {};
      const accessories = data.map(async (accessory) => {
        const accessorySku = accessory.partNumber;
        // Build the Category Names set
        categorySet.add(accessory.categoryName);
        // TODO: Temporary fix until Data Team updates the data and removes 'Apps' category
        // Treat 'Apps' and 'Sports Apps' as 'Sports Apps' -> essentially, have only one category
        const globalCategoryName = (accessory.globalCategoryName === 'Apps' || accessory.globalCategoryName === 'Sports Apps')
          ? 'Sports Apps'
          : accessory.globalCategoryName;
        // Build the Global Category Names set -> Get from the response only the unique global category names
        globalCategorySet.add(globalCategoryName);
        // Build the showSections array (for each button assign its open/closed state)
        showSectionsArray[accessory.categoryName] = false;
        // Add the link to the accessory
        accessory.link = templateBuilder(getters.getSeoUrl, { seoUrl: accessory.seoUrl });

        // Add the Add to Cart link
        const addToCartUrl = getters.getAddToCartUrl;

        accessory.addToCartLink = templateBuilder(addToCartUrl, {
          partNum: accessorySku,
          quantity: '1',
        });
        return accessory;
      });

      // Sets do not work well with IE, reconverting to array
      const categoryNames = [...categorySet];
      const globalCategoryNames = [...globalCategorySet];

      commit('setGlobalCategoryNames', globalCategoryNames);
      commit('setCategoryNames', categoryNames);
      commit('setShowSections', showSectionsArray);

      await Promise.all(accessories).then((response) => {
        const cacheValue = {
          data: response,
          partNumber: sku,
        };
        commit('setAccessories', cacheValue);
        commit('setAccessoriesLoading', false);
      });
    },
    async setAccessoriesPrices({ commit, dispatch, getters }, category) {
      const categoryAccessories = getters.getActiveAccessoriesByCategory(category);
      const partNumber = getters.getPartNumber;
      // Set the prices for accessories matching the category
      const accessoriesPricesPromise = categoryAccessories.map((accessory) => dispatch('setAccessoryPrice', accessory));
      const pricedAccessories = await Promise.all(accessoriesPricesPromise);

      // Get current active Accessories
      const activeAccessories = getters.getActiveAccessories;
      // if no accessories were priced, return original accessories
      if (!pricedAccessories.length) return activeAccessories;

      // Replace the accessories in the active accessories with the newly priced accessories
      const newAccessories = activeAccessories.reduce((newAccs, acc) => {
        const pricedAcc = pricedAccessories.find((priced) => priced.partNumber === acc.partNumber);
        const newAcc = pricedAcc || acc;

        newAccs.push(newAcc);

        return newAccs;
      }, []);

      const payload = {
        data: newAccessories,
        partNumber,
      };

      await commit('setAccessories', payload);
      return newAccessories;
    },

    async setAccessoryPrice({ getters, commit, rootGetters }, accessory) {
      const { getCustomerGroups } = rootGetters;
      const newAccessory = { ...accessory };
      const { partNumber } = newAccessory;
      const { getLocale, getCountryCode, getBuyGarminEndpoint } = getters;

      const queryParams = new URLSearchParams();
      queryParams.append('locale', getLocale);
      /**
       * If there are multiple customerGroups, they will be divided by a |
       * new URLSearchParams() will convert | to %7C which the pricing APIs expect when there are multiple customer groups
       */
      queryParams.append('customerGroup', getCustomerGroups);

      const locationHref = window?.location?.href;
      if (locationHref.includes('cdncache=false')) queryParams.append('cdncache', false);

      const priceUrl = `${getBuyGarminEndpoint}/pricing-proxy-services/countries/${getCountryCode}/skus/${partNumber}/price?${queryParams}`;

      // If accessory price has already been called and cached, return null
      // returning null indicates the price was not called
      const cachedPrice = getters.getAccessoryPriceByPriceUrl(
        priceUrl,
      );

      try {
        // if the price call is already cached, return that, else call to get price
        const priceData = cachedPrice || await request({
          method: 'GET',
          url: priceUrl,
        });

        // Set priceObj
        const priceObj = priceData || {};
        const { listPrice, salePrice, savings } = priceData;

        // Add formatted listPrice, formatted salePrice, and formatted savings onto priceObj
        if (priceObj.listPrice) {
          priceObj.formattedHTMLPrice = priceTemplateBuilder(listPrice);
        }

        if (priceObj.salePrice) {
          priceObj.formattedHTMLSalePrice = priceTemplateBuilder(salePrice);
        }

        if (priceObj.savings) {
          priceObj.formattedHTMLSavings = priceTemplateBuilder(savings);
        }

        // set that price api has been called and has finished loading
        priceObj.priceLoaded = true;

        const newPrice = {
          data: priceObj,
          priceUrl,
        };

        // Add the price to the accessory
        newAccessory.priceObj = priceObj;

        // Cache accessory price
        commit('setAccessoryPrice', newPrice);

        return newAccessory;
      } catch (err) {
        /* eslint-disable-next-line no-console */
        console.warn(err);
        return {};
      }
    },
    async setActiveSection({ commit, dispatch }, payload) {
      commit('setActiveSection', payload);

      // if payload != null, i.e active section is a category
      if (payload) {
        commit('setSectionAccessoriesPricesLoaded', false);
        await dispatch('setAccessoriesPrices', payload);
        commit('setSectionAccessoriesPricesLoaded', true);
      }
    },
    async setViewAllSections({ commit, dispatch, getters }, payload) {
      commit('setViewAllSections', payload);

      // if ViewAll section is expanded
      if (payload) {
        // Get all categories from active sections
        const activeAccessories = [...getters.getActiveAccessories];
        const categories = activeAccessories.map((accessory) => accessory.categoryName);
        const uniqueCategories = [...new Set(categories)];

        // Loop over categories and dispatch/fetch prices for each
        commit('setSectionAccessoriesPricesLoaded', false);
        const viewAllAccessoriesPricesPromises = uniqueCategories.map((category) => dispatch('setAccessoriesPrices', category));
        await Promise.all(viewAllAccessoriesPricesPromises);
        commit('setSectionAccessoriesPricesLoaded', true);
      }
    },
    setNewOverviewTab({ commit }, payload) {
      commit('setNewOverviewTab', payload);
    },
    setDevicesTabTitle({ commit }, payload) {
      commit('setDevicesTabTitle', payload);
    },
    setCategoriesListCollapsed({ commit }, payload) {
      commit('setCategoriesListCollapsed', payload);
    },
  },
};
