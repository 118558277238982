<template>
  <div class="app__kicker__wrapper js__product__kicker">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.app__kicker__wrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4px;
}
</style>
