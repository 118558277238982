<script>
/* Imports */
import { mapActions, mapGetters } from 'vuex';

/* Analytics */
import {
  sendAnalyticsEvent,
  sanitizeAnalyticsString,
  getCategory3Data,
  getSelectedEmsOption,
  getSelectedEmsOptionIndex,
  getAnalyticsPriceData,
} from '../helpers/ga/analytics';

/* Helpers */
import {
  getPartNumberFromUrl,
  getWwwUrl,
  replaceSuperScript,
} from '../helpers/mainHelpers';

/* Composables */
import { useGenericAnalyticsDataHelper } from '../composables/useAnalyticsData';

/* Components */
import OldTooltip from './OldTooltip.vue';

export default {
  name: 'OldEmsFilters',
  components: {
    OldTooltip,
  },
  props: {
    currencyCode: {
      type: String,
      default: '',
    },
  },
  setup() {
    /* Initialize Composables */
    const { genericAnalyticsData } = useGenericAnalyticsDataHelper();
    return { genericAnalyticsData };
  },
  computed: {
    ...mapGetters([
      'getProductId',
      'getProductVariation',
      'getGlobalProductName',
      'getPriceData',
      'getSeoAttributes',
      'getPartNumber',
      'getOptionsList',
      'getOptionListByType',
      'getAllOptionItems',
      'getFilterDropdownSelected',
      'getFilterDropdownList',
      'getWwwGarminEndpoint',
      'getBuyGarminEndpoint',
      'getProductBySku',
    ]),
    isDropdown() {
      return this.getFilterDropdownList?.length > 1;
    },
  },
  methods: {
    ...mapActions(['updateProductData', 'setFilterDropdownSelected']),
    optionsList() {
      if (this.isDropdown) {
        return this.getOptionsList;
      }
      return this.getOptionListByType('ems');
    },
    getButtons(emsCategory) {
      return this.getAllOptionItems(emsCategory);
    },
    getUrl(url) {
      return getWwwUrl(url, this.getBuyGarminEndpoint, this.getWwwGarminEndpoint);
    },
    calculateLinkClass(button) {
      return {
        'app__product__filters__buttons--selected': button.selected,
        js__selected: button.selected,
        app__product__filters__buttons: true,
        'js__filter-option': true,
      };
    },
    getAnalyticsProductName() {
      return sanitizeAnalyticsString(this.getGlobalProductName) || 'NA';
    },
    getCategory3Value() {
      const productName = this.getAnalyticsProductName();
      return getCategory3Data(productName);
    },
    sendProductVariationEvent(sku) {
      if (!sku) return;

      const productPrice = {
        priceObj: this.getPriceData,
        sellable: this.getProductBySku(sku)?.isSellable,
        showPrice: this.getProductBySku(sku)?.showPrice,
      };

      const analyticsData = {
        ...this.genericAnalyticsData(
          'Product Page EMS',
          'Product Page EMS - Button',
        ),
        // Send the following fields as 'NA' for now since they are not available in the current context
        creative_name: 'NA',
        creative_slot: 'NA',
        location_id: 'NA',
        promotion_name: 'NA',
        promotion_id: 'NA',
        bundle_id: 'NA',
        is_bundle: 'NA',
        sale_type: 'NA',
        index: getSelectedEmsOptionIndex(this.getOptionListByType('image')),
        product_id: this.getProductId ? [this.getProductId] : ['NA'],
        product_brand: window?.utag_data?.product_brand || ['NA'],
        product_category: window?.utag_data?.product_category || ['NA'],
        product_category2: window?.utag_data?.product_category2 || ['NA'],
        product_category3: this.getCategory3Value(),
        product_category4: getSelectedEmsOption(this.getOptionsList, 0),
        product_category5: getSelectedEmsOption(this.getOptionsList, 1),
        product_variant: this.getProductVariation ? [sanitizeAnalyticsString(this.getProductVariation)] : ['NA'],
        product_name: [this.getAnalyticsProductName()],
        product_original_price: [getAnalyticsPriceData(productPrice).listPrice],
        product_price: [getAnalyticsPriceData(productPrice).price],
        product_sku: sku ? [sku] : ['NA'],
        product_url: window?.location?.href ? [window.location.href.split('?')[0]] : ['NA'],
        product_availability: [getAnalyticsPriceData(productPrice).availability],
        item_family: this.getCategory3Value(),
        is_sale: getAnalyticsPriceData(productPrice).isSale,
        in_stock: getAnalyticsPriceData(productPrice).inStock,
        price_shown: getAnalyticsPriceData(productPrice).priceShown,
        link_type: ['EMS button'],
        tealium_event: 'select_item',
      };

      sendAnalyticsEvent(analyticsData);
    },
    findSelectedButton(buttonList) {
      return buttonList.find((obj) => obj.selected === true) ?? {};
    },
    async changeOption(button) {
      // update the product data
      await this.updateProductData(button?.sku);

      // send analytics event
      this.sendProductVariationEvent(button?.sku);
    },
    async changeDropdown(e) {
      const url = e.target.value;
      const sku = getPartNumberFromUrl(url);
      // if a sku is not found in url, redirect the page to the url
      // this is fallback in case the dropdown value provided doesn't have the sku (bad data)
      if (!sku) {
        window.location.assign(url);
        return;
      }
      this.setFilterDropdownSelected(url);

      // update the product data
      await this.updateProductData(sku);

      // send analytics event
      this.sendProductVariationEvent(sku);
    },
    enhanceTextWithSuperScript(text) {
      return replaceSuperScript(text);
    },
    sanitizeDropdownText(text) {
      return sanitizeAnalyticsString(text);
    },
  },
};
</script>

<template>
  <div
    id="js__filters-section"
    class="app__product__filters"
  >
    <div
      v-for="option in optionsList()"
      :key="option?.id"
      class="app__product__filters__group"
    >
      <template v-if="isDropdown">
        <g-drop-down
          class="app__product__filters__dropdown"
          :label="enhanceTextWithSuperScript(option?.title)"
        >
          <select
            name="Select Map"
            class="app__product__filters__dropdown__select"
            @change="changeDropdown($event)"
          >
            <option
              v-for="getFilterElement in getFilterDropdownList"
              :key="getFilterElement?.value"
              :value="getFilterElement?.value"
              :selected="getFilterElement?.selected"
              v-html="getFilterElement?.text"
            />
          </select>
        </g-drop-down>
      </template>
      <template v-else>
        <div class="app__product__filters__label">
          <OldTooltip
            :title="option?.title"
            :tooltip-text="option?.tooltip || 'undefined'"
          />
        </div>
        <div class="js__filters-wrapper">
          <a
            v-for="button in getButtons(option?.id)"
            :key="button?.text"
            :href="getUrl(button?.url)"
            :class="calculateLinkClass(button)"
            @click.prevent="changeOption(button)"
            v-html="enhanceTextWithSuperScript(button?.text)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
@mixin selected-filter-styles {
  background-color: $color-black;
  border-color: $color-black;
  color: $color-white;
}

.app__product__filters {
  margin-bottom: 1em;

  &__group {
    margin: 0.5em 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include productBreakpoint(desktop-s) {
      align-items: flex-start;
    }
  }

  &__label {
    margin-bottom: 0.3em;
  }

  &__buttons {
    display: inline-block;
    text-transform: uppercase;
    margin: 0 0.75em 0.75em 0;
    color: $color-black;
    background-color: $color-white;
    border: 2px solid $gray-medium-light;
    font-size: 0.72em;
    padding: 0.5em 1.25em;
    font-weight: 400;
    transition: background-color 0.5s, border-color 0.5s, color 0.5s;

    &:hover {
      color: $color-black;
      background: $color-white;
      border-color: $color-black;
    }

    &--selected {
      pointer-events: none;
      background-color: $color-black;
      border-color: $color-black;
      color: $color-white;
      @include selected-filter-styles();

      &:visited,
      &:focus {
        color: $color-white;
      }

      &:hover {
        @include selected-filter-styles();
      }
    }
  }

  &__dropdown {
    font-size: 0.9em;
    width: fit-content;

    &__select {
      padding: 1em 2.5em 1em 1em;
      color: $gray-drop-down;
    }
  }
}

:deep() .g__dropdown__label {
  font-size: 1em;

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.5rem;
    align-items: center;

    @include productBreakpoint(desktop-s) {
      align-items: flex-start;
    }
  }
}
</style>
