<script>
/* Imports */
import { mapActions, mapGetters } from 'vuex';

/* Components */
import VatTooltip from '../components/VatTooltip.vue';
import Titles from '../components/Titles.vue';
import ProductAvailability from '../components/ProductAvailability.vue';
import ProductFootnote from '../components/ProductFootnote.vue';
import KickerWrapper from '../components/KickerWrapper.vue';
import Kicker from '../components/Kicker.vue';
import PriceWrapper from '../components/PriceWrapper.vue';
import KlarnaWrapper from '../components/KlarnaWrapper.vue';
import KlarnaWidget from '../components/KlarnaWidget.vue';
import CtasWrapper from '../components/CtasWrapper.vue';
import ColorPicker from '../components/ColorPicker.vue';
import EmsFilters from '../components/EmsFilters.vue';

export default {
  name: 'ProductInfo',
  components: {
    Titles,
    Kicker,
    KickerWrapper,
    ProductAvailability,
    PriceWrapper,
    KlarnaWrapper,
    KlarnaWidget,
    CtasWrapper,
    ProductFootnote,
    ColorPicker,
    EmsFilters,
    VatTooltip,
  },
  data() {
    return {
      priceIsLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      productName: 'getProductName',
      showProductName: 'getShowProductName',
      productVariation: 'getProductVariation',
      showProductVariation: 'getShowProductVariationName',
      partNumber: 'getPartNumber',
      showPartNumber: 'getShowPartNumber',
      showKicker: 'getShowProductKicker',
      kickerContent: 'getProductKickerContent',
      kickerText: 'getProductKickerText',
      loadingPrice: 'getLoadingPrice',
      price: 'getFormattedPrice',
      showPrice: 'getShowPrice',
      showFormattedPrice: 'getShowFormattedPrice',
      salePrice: 'getFormattedSalePrice',
      showSalePrice: 'getShowFormattedSalePrice',
      savings: 'getFormattedSavings',
      showSavings: 'getShowFormattedSavings',
      saveText: 'getPriceSaveText',
      shippingDisclaimer: 'getShippingDisclaimer',
      showShippingDisclaimer: 'getShowShippingDisclaimer',
      addToCartLoading: 'getAddToCartLoading',
      showBuyAction: 'getHasBuyAction',
      addToCartHref: 'getBuyActionHref',
      addToCartText: 'getBuyActionText',
      showDealerAction: 'getHasDealerAction',
      dealerActionHref: 'getDealerActionHref',
      dealerActionText: 'getDealerActionText',
      expressWarning: 'getExpressWarning',
      showExpressWarning: 'getShowExpressWarning',
      productHeaderDetails: 'getProductHeaderDetails',
      showProductHeaderDetails: 'getShowProductHeaderDetails',
      productAvailability: 'getProductAvailability',
      showProductAvailability: 'getShowProductAvailability',
      vatToolTip: 'getVatToolTip',
      showVatToolTip: 'getShowVatToolTip',
      productLegalText: 'getProductLegalText',
      showProductLegalText: 'getShowProductLegalText',
      productMarketingSummary: 'getProductMarketingSummary',
      showProductMarketingSummary: 'getShowProductMarketingSummary',
      showAddToCartBtn: 'getShowAddToCartBtn',
      widgetMetadata: 'getWidgetMetadata',
    }),
    displayPrice() {
      return this.showPrice && this.showFormattedPrice;
    },
    currencyCode() {
      return window?.utag_data?.currency_code;
    },
    showKlarnaWidget() {
      /**
       * If this.widgetMetadata has keys, we got a valid response from
       * /marketing-services/widgets/klarna and should display the widget
       */
      return Object.keys(this.widgetMetadata)?.length;
    },
  },
  watch: {
    partNumber() {
      this.callInterstitialAccessories();
    },
  },
  mounted() {
    this.callInterstitialAccessories();
    this.$nextTick(() => {
      this.handleLoadingIndicator();
    });
  },
  methods: {
    ...mapActions(['setAddToCartLoadingState', 'callInterstitialAccessories']),
    handleLoadingIndicator() {
      /* Hide the loading indication after 1 second */
      /* eslint-disable no-return-assign */
      setTimeout(() => this.priceIsLoading = false, 1000);
    },
  },
};
</script>

<template>
  <!-- TODO: Remove new__ prefix after getProductPageRedesignContentfulFeatureFlag is removed -->
  <div
    id="js__product__info"
    class="new__app__product__info"
  >
    <Titles
      :title="productName"
      :show-product-name="showProductName"
      :subtitle="productVariation"
      :show-subtitle="showProductVariation"
      :part-number="partNumber"
      :show-part-number="showPartNumber"
    />
    <KickerWrapper
      v-if="showKicker"
      id="js__product__info__kicker"
    >
      <Kicker
        :content="kickerContent"
        :text="kickerText"
      />
    </KickerWrapper>
    <ProductAvailability
      v-if="showProductAvailability"
      :content="productAvailability"
    />
    <div
      v-if="priceIsLoading"
      class="app__product__price-loader-wrapper"
    >
      <g-loader type="default" />
    </div>
    <PriceWrapper
      v-else
      :price="price"
      :show-price="displayPrice"
      :sale-price="salePrice"
      :show-sale-price="showSalePrice"
      :savings="savings"
      :show-savings="showSavings"
      :translations="saveText"
    />
    <KlarnaWrapper
      v-if="showKlarnaWidget"
      id="js__product__info__klarna"
    >
      <KlarnaWidget :widget-metadata="widgetMetadata" />
    </KlarnaWrapper>

    <VatTooltip
      v-if="showVatToolTip"
      :content="vatToolTip"
    />
    <EmsFilters
      :currency-code="currencyCode"
    />
    <ColorPicker
      :currency-code="currencyCode"
    />
    <CtasWrapper
      :show-add-to-cart-btn="showAddToCartBtn"
      :add-to-cart-text="addToCartText"
      :add-to-cart-href="addToCartHref"
      :add-to-cart-loading="addToCartLoading"
      :set-add-to-cart-loading="setAddToCartLoadingState"
      :show-dealer-btn="showDealerAction"
      :dealer-text="dealerActionText"
      :dealer-href="dealerActionHref"
      :express-warning="expressWarning"
      :show-express-warning="showExpressWarning"
      :currency-code="currencyCode"
    />
    <ProductFootnote
      :shipping-disclaimer-text="shippingDisclaimer"
      :show-shipping-disclaimer="showShippingDisclaimer"
      :product-header-details="productHeaderDetails"
      :show-product-header-details="showProductHeaderDetails"
      :product-legal-text="productLegalText"
      :show-product-legal-text="showProductLegalText"
      :product-marketing-summary="productMarketingSummary"
      :show-product-marketing-summary="showProductMarketingSummary"
      :part-number="partNumber"
    />
  </div>
</template>

<style lang="scss">
// TODO: Remove new__ prefix after getProductPageRedesignContentfulFeatureFlag
// is removed

.new__app__product {

  &__info {
    @extend %product-section;
    flex-basis: auto;
    display: block;
    max-width: 100%;
    text-align: left;
    margin-bottom: 1rem;

    @include productBreakpoint('desktop-s') {
      flex-basis: auto;
      max-width: 50vw;
      padding-right: 7%;
      /* stylelint-disable-next-line */
      padding-right: 7vw;
    }

    @include productBreakpoint('desktop-l') {
      padding-right: 10%;
      /* stylelint-disable-next-line */
      padding-right: 10vw;
    }

    a {
      color: $color-garmin-blue-40;
    }
  }

  &__price-loader-wrapper {
    max-width: 2rem;
    margin: 1rem auto 2.5rem auto;

    // Left aligns the loader on larger screens
    @include productBreakpoint(desktop-s) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
