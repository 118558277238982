/* eslint-disable key-spacing */
import { createStore } from 'vuex';

// modules
import global from './global';
import content from './content';
import customerGroups from './customerGroups';
import gallery from './gallery';
import productInfo from './productInfo';
import productOptions from './productOptions';
import productTabs from './productTabs';
import productSidebar from './productSidebar';
import priceInfo from './priceInfo';
import mapsData from './mapsData';
import ctas from './ctas';
import interstitial from './interstitial';
import contentfulFeatureFlags from './contentfulFeatureFlags';

export const storeSettings = {
  modules: {
    global,
    content,
    customerGroups,
    gallery,
    productInfo,
    productOptions,
    productTabs,
    mapsData,
    productSidebar,
    priceInfo,
    ctas,
    interstitial,
    contentfulFeatureFlags,
  },
  state: {
    bootstrap: null,
    customerGroup: 'none',
  },
  getters: {
    getBootstrap(state) {
      return state.bootstrap;
    },
    getProductBySku: (state, getters) => (sku) => {
      const bootstrap = getters.getBootstrap;

      return bootstrap.skus[sku];
    },
  },
  mutations: {
    setBootstrap(state, payload) {
      state.bootstrap = payload;
    },
  },
  actions: {
    async setBootstrap({ commit, dispatch }) {
      const bsData = { ...window.GarminAppBootstrap };
      commit('setBootstrap', bsData);
      await dispatch('setInitState');
    },
    async setInitState({ dispatch, getters }) {
      const bootstrap = getters.getBootstrap;
      const {
        translations,
        regions,
        sku,
        skus,
        filterDropdownList,
        devicesTabTitle,
        productEmsList,
      } = bootstrap;
      const product = skus[sku];

      const productOptionsList = {
        productEmsList,
        filterDropdownList,
      };
      const { tabs } = product;
      dispatch('fetchContentfulEnvSettings');
      dispatch('setGlobalState', bootstrap);
      dispatch('setContent', translations);
      dispatch('setContentfulRegions', regions);
      dispatch('setDevicesTabTitle', devicesTabTitle);
      dispatch('setProductOptionsList', productOptionsList);
      await dispatch('setCustomerGroups');
      await dispatch('setProductData', product);
      dispatch('setProductTabs', tabs);
    },
    async updateProductData({ dispatch, getters }, sku) {
      const product = getters.getProductBySku(sku);
      if (!product) return;

      const { tabs } = product;
      const tabsSettings = {
        tabsPayload: tabs,
      };

      await dispatch('setProductData', product);
      dispatch('updateProductTabs', tabsSettings);
      dispatch('resetInterstitial');
    },
    async setProductData({ dispatch, getters }, product) {
      const optionsList = getters.getProductEmsList;

      const {
        images,
        actions,
        expressWarning,
        options,
        sidebar,
      } = product;
      const ctasState = {
        actions: { ...actions },
        expressWarning,
      };
      const optionsData = {
        optionsList,
        options,
      };
      dispatch('setGallery', images);
      dispatch('setProductInfo', product);
      dispatch('setProductOptions', optionsData);
      await dispatch('callPricingProxy');
      dispatch('callKickersApi');
      dispatch('callKlarnaApi');
      dispatch('setSidebar', sidebar);
      dispatch('setCtas', ctasState);
    },
  },
};

export default createStore(storeSettings);
