<script>
/* Imports */
import { mapGetters } from 'vuex';

/* Helpers */
import { replaceSuperScript } from '../helpers/mainHelpers';

export default {
  name: 'Titles',
  props: {
    title: {
      type: String,
      default: '',
    },
    showProductName: {
      type: Boolean,
      default: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    showSubtitle: {
      type: Boolean,
      default: true,
    },
    partNumber: {
      type: String,
      default: '',
    },
    showPartNumber: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      partNumberTitle: 'getPartNumberTitle',
    }),
    computedProductName() {
      return replaceSuperScript(this.title);
    },
    computedSubtitle() {
      return replaceSuperScript(this.subtitle);
    },
    computedPartNumberTitle() {
      return replaceSuperScript(this.partNumberTitle);
    },
  },
};
</script>

<template>
  <div id="js__product__meta">
    <g-heading
      v-if="showProductName"
      id="js__product__info__name"
      class="app__product__info__title"
      :content="computedProductName"
      text-align="left"
    />
    <p
      v-if="showSubtitle"
      id="js__product__info__variation"
      class="app__product__info__subtitle"
      v-html="computedSubtitle"
    />
    <p
      v-if="showPartNumber"
      id="js__product__info__part-number"
      class="app__product__info__part-number"
    >
      <span
        id="js__product__info__part-number__label"
        v-html="computedPartNumberTitle"
      />
      <span
        id="js__product__info__part-number__text"
        class="app__product__info__part-number--light"
      >
        {{ partNumber }}
      </span>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.app__product {
  position: relative;
  display: flex;
  flex-flow: row wrap;

  &__info {

    &__title {

      :deep(h1) {
        margin: 0;
      }
    }

    &__subtitle {
      font-size: 1.11em;
      line-height: 2rem;
      @include font-primary-weight-regular();
      color: $color-black;
      margin: 0 auto 0.5em;

      @include productBreakpoint('desktop-s') {
        margin-left: 0;
        margin-right: 0;
      }
    }

    /* Special scenario for the symbols */

    sup.tm-symbol {
      vertical-align: 0.6rem;
    }

    sup.sm-symbol {
      vertical-align: 0.55rem;
    }

    sup.registered-symbol {
      vertical-align: 0.65rem;
    }

    &__part-number {
      font-size: 0.7em;
      font-weight: 700;
      text-transform: uppercase;
      color: $color-black;
      margin: 1em 0;

      &--light {
        padding-left: 0.2rem;
        font-weight: 500;
      }
    }
  }
}
</style>
