<script>
/* Imports */
import { mapActions, mapGetters } from 'vuex';

/* Analytics */
import {
  sanitizeAnalyticsString,
  sendAnalyticsEvent,
} from '../helpers/ga/analytics';

/* Helpers */
import { replaceSuperScript, breakpoints } from '../helpers/mainHelpers';

/* Composables */
import { useBackToTopBtn } from '../composables/useBackToTopBtn';

/* Components */
import TabLoader from '../components/TabLoader.vue';
import Accessories from './Accessories.vue';
import BackToTopBtn from '../components/BackToTopBtn.vue';

export default {
  name: 'AccessoriesContainer',
  components: {
    TabLoader,
    Accessories,
    BackToTopBtn,
  },
  setup() {
    // Inject composables - BackToTop
    const { showBackToTopBtn, backToTopBtnRef } = useBackToTopBtn();
    return { showBackToTopBtn, backToTopBtnRef };
  },
  data() {
    return {
      mobileHeadingContent: {
        alignment: 'left',
        color: 'black',
        size: '4',
        content: 'Categories',
      },
    };
  },
  computed: {
    ...mapGetters({
      categoryNames: 'getCategoryNames',
      globalCategoryNames: 'getGlobalCategoryNames',
      accessoriesLoading: 'getAccessoriesLoading',
      tabNoAccessories: 'getTabNoAccessories',
      tabErrorAccessories: 'getAccessoriesTabError',
      showNoAccessoriesText: 'getShowNoAccessoriesText',
      errorAccessoriesText: 'getErrorAccessoriesText',
      productActiveTabId: 'getProductActiveTabId',
      accessoriesTabName: 'getAccessoriesTabTitle',
      sectionSelected: 'getActiveSection',
      viewAllSections: 'getViewAllSections',
      viewAllAccessoriesText: 'getExpandSection',
      showCompatibleDevices: 'getShowCompatibleDevicesTab',
      devicesData: 'getActiveCompatibleDevices',
      noAccessoriesAvailable: 'getShowNoAccessoriesText',
      categoriesListCollapsed: 'getCategoriesListCollapsed',
      priceData: 'getPriceData',
    }),
    navContent() {
      return [{
        id: 0,
        categoryLabel: this.accessoriesTabName,
        isOpen: true,
        subcategories: this.categoryNames.map((category, index) => ({
          linkUrl: '#accessories',
          subCategoryLabel: replaceSuperScript(category),
          isActive: this.sectionSelected === category,
          globalCategoryName: this.globalCategoryNames[index],
        })),
      }];
    },
    currencyCode() {
      return window?.utag_data?.currency_code;
    },
    showAccessories() {
      return !this.noAccessoriesAvailable;
    },
    moreThanOneCategory() {
      return this.categoryNames?.length !== 1;
    },
  },
  watch: {
    productActiveTabId() {
      this.callAccessories();
    },
  },
  created() {
    this.callAccessories();
  },
  methods: {
    ...mapActions([
      'callAccessories',
      'setActiveSection',
      'setViewAllSections',
      'setCategoriesListCollapsed',
    ]),
    handleSelection(event, selectedGlobalCategoryName) {
      event.preventDefault();
      this.setViewAllSections(false);
      const selectedCategory = event?.target?.textContent;
      const categoryNamesIncludesSelectedCategory = selectedCategory
        && (this.categoryNames.indexOf(selectedCategory) !== -1);

      if (categoryNamesIncludesSelectedCategory) {
        sendAnalyticsEvent({
          tealium_event: 'product_page_click',
          link_type: 'card',
          event_category: `Product Page >> Accessories Tab >> Category >> ${sanitizeAnalyticsString(selectedGlobalCategoryName) ?? ''}`,
          event_action: 'click',
          event_label: sanitizeAnalyticsString(selectedGlobalCategoryName) ?? '',
          product_sku: this.priceData?.partNumber ?? '',
          productPrice: this.priceData?.listPrice?.price ?? '',
          discount: this.priceData?.savings ?? '',
        });

        // If mobile, toggle collapsed list on click of category item
        if (window.screen.width <= breakpoints['screen-sm']) {
          this.setCategoriesListCollapsed(!this.categoriesListCollapsed);
          if (this.categoriesListCollapsed) {
            this.setActiveSection(selectedCategory);
          } else {
            this.setActiveSection(null);
          }
        } else {
          // On larger screens always just show selected category on click
          this.setActiveSection(selectedCategory);
        }
      }
    },
    async handleViewAllSections(event) {
      event.preventDefault();
      this.setActiveSection(null);

      // If mobile, toggle viewAllSections and collapse list on click
      if (window.screen.width <= breakpoints['screen-sm']) {
        await this.setViewAllSections(!this.viewAllSections);
        this.setCategoriesListCollapsed(!this.categoriesListCollapsed);
      } else {
        // On larger screens always just show all on click
        await this.setViewAllSections(true);
      }
    },
  },
};
</script>

<template>
  <div
    ref="backToTopBtnRef"
    class="app__product__accessories"
  >
    <TabLoader
      :show="accessoriesLoading"
    />
    <span v-if="showNoAccessoriesText">
      {{ tabNoAccessories }}
    </span>
    <span v-if="errorAccessoriesText">
      {{ tabErrorAccessories }}
    </span>
    <div
      v-show="!accessoriesLoading"
      class="app__product__accessories-container"
    >
      <div
        v-if="showAccessories"
        class="app__product__accessories-container-inner"
      >
        <nav class="app__product__accessories-nav">
          <ul
            class="app__product__accessories-nav__list"
            :data-categories-list-collapsed="categoriesListCollapsed"
          >
            <li
              v-for="subCategory in navContent[0]?.subcategories"
              :key="subCategory.subCategoryLabel"
              :class="{
                'app__product__accessories-nav__list-item': true,
                'app__product__accessories-nav__list-item--active': subCategory.isActive,
              }"
            >
              <a
                class="app__product__accessories-nav__link"
                :href="subCategory.linkUrl"
                :data-category="subCategory.subCategoryLabel"
                @click="(event) => handleSelection(event, subCategory.globalCategoryName)"
                v-html="subCategory.subCategoryLabel"
              />
            </li>
            <li
              v-if="moreThanOneCategory"
              :class="{
                'app__product__accessories-nav__list-item': true,
                'app__product__accessories-nav__list-item--active': viewAllSections,
              }"
            >
              <a
                class="app__product__accessories-nav__link"
                href="#accessories"
                @click="handleViewAllSections"
              >
                {{ viewAllAccessoriesText }}
              </a>
            </li>
          </ul>
        </nav>
        <Accessories />
      </div>
    </div>
    <BackToTopBtn v-if="showBackToTopBtn" />
  </div>
</template>

<style lang="scss">
.app__product__accessories {
  width: 100%;

  &-container-inner {
    display: flex;
    flex-direction: column;

    @include breakpoint(sm) {
      flex-direction: row;
    }
  }

  &-nav {
    @include breakpoint(sm) {
      flex-basis: 21.429em; // 300px
      padding-right: 1em;
    }
  }

  &-nav__list,
  &-nav__list-item {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  // stylelint-disable-next-line max-line-length, rule-empty-line-before
  &-nav__list[data-categories-list-collapsed = 'true'] .app__product__accessories-nav__list-item:not(.app__product__accessories-nav__list-item--active) {
    display: none;

    @include breakpoint(sm) {
      display: block;
    }
  }

  // Extra specificity needed to overwrite
  // existing styles in tab content styling

  &-nav__list-item a.app__product__accessories-nav__link {
    display: block;
    padding: 0.65em 0 0.65em 1.75rem;
    @extend %sidebar-links;
    @extend %text-black;
    @extend %text-decoration-none;

    &:focus:not(:focus-visible) {
      outline: 0;
      box-shadow: none;
    }

    &:visited {
      @extend %text-black;
    }

    @include breakpoint(sm) {
      padding-left: 2.5em;
    }
  }

  &-nav__list-item--active a.app__product__accessories-nav__link,
  &-nav__list-item--active a.app__product__accessories-nav__link:visited {
    background: $color-black;
    color: $color-white;
  }
}
</style>
