/* Helpers */
import { priceTemplateBuilder } from '../helpers/mainHelpers';

export default {
  state: {
    priceData: null,
    loadingPrice: true,
  },
  getters: {
    getPriceData(state) {
      return state.priceData;
    },
    getPrice(state, getters) {
      const priceData = getters.getPriceData;
      return priceData ? priceData.listPrice : null;
    },
    getFormattedPrice(state, getters) {
      const price = getters.getPrice;
      return price ? price.formattedPrice : '';
    },
    // This seems to get called before we get our data back
    getShowFormattedPrice(state, getters) {
      return !!getters.getFormattedPrice.length;
    },
    getSalePrice(state, getters) {
      const priceData = getters.getPriceData;
      return priceData ? priceData.salePrice : null;
    },
    getFormattedSalePrice(state, getters) {
      const salePrice = getters.getSalePrice;
      return salePrice ? salePrice.formattedPrice : '';
    },
    getShowFormattedSalePrice(state, getters) {
      return !!getters.getFormattedSalePrice.length;
    },
    getSavings(state, getters) {
      const priceData = getters.getPriceData;
      return priceData ? priceData.savings : null;
    },
    getFormattedSavings(state, getters) {
      const savings = getters.getSavings;
      return savings ? savings.formattedPrice : '';
    },
    getShowFormattedSavings(state, getters) {
      return !!getters.getFormattedSavings.length;
    },
    getLoadingPrice(state) {
      return state.loadingPrice;
    },
  },
  mutations: {
    setPriceData(state, payload) {
      state.priceData = payload;
    },
    setLoadingPrice(state, payload) {
      state.loadingPrice = payload;
    },
  },
  actions: {
    async callPricingProxy({ commit, getters, rootGetters }) {
      commit('setLoadingPrice', true);
      const { getCustomerGroups } = rootGetters;
      const {
        getBuyGarminEndpoint,
        getLocale,
        getCountryCode,
        getPartNumber,
      } = getters;

      const queryParams = new URLSearchParams();
      queryParams.append('locale', getLocale);
      /**
       * If there are multiple customerGroups, they will be divided by a |
       * new URLSearchParams() will convert | to %7C which the pricing APIs expect when there are multiple customer groups
       */
      queryParams.append('customerGroup', getCustomerGroups);

      const locationHref = window?.location?.href;
      if (locationHref.includes('cdncache=false')) queryParams.append('cdncache', false);

      const url = `${getBuyGarminEndpoint}/pricing-proxy-services/countries/${getCountryCode}/skus/${getPartNumber}/price?${queryParams}`;

      try {
        // Using fetch with credentials: 'include' instead of axios
        // axios with the withCredentials: true setting was not correctly sending cookies
        // with the request
        // More info here: https://github.com/axios/axios/issues/2149
        const response = await fetch(url, {
          method: 'get',
          credentials: 'include',
        });

        const priceData = await response.json();

        if (!priceData) {
          commit('setLoadingPrice', false);
          // If no price data, set price back to null
          commit('setPriceData', null);
          return;
        }

        // Generate formatted prices
        if (priceData.listPrice) {
          priceData.listPrice.formattedPrice = priceTemplateBuilder(priceData.listPrice);
        }

        if (priceData.salePrice) {
          priceData.salePrice.formattedPrice = priceTemplateBuilder(priceData.salePrice);
        }

        if (priceData.savings) {
          priceData.savings.formattedPrice = priceTemplateBuilder(priceData.savings);
        }

        commit('setPriceData', priceData);
        commit('setLoadingPrice', false);
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error calling pricing proxy service');
        // If no price data due to error, set price back to null
        commit('setPriceData', null);
        commit('setLoadingPrice', false);
      }
    },
  },
};
