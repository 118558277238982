export default {
  state: {
    contentfulEnvSettings: {},
    // TODO: Remove this flag once the feature is fully implemented
    productPageRedesignContentfulFeatureFlag: false,
  },
  getters: {
    getContentfulEnvSettings: (state) => state.contentfulEnvSettings,
    // TODO: Remove getProductPageRedesignContentfulFeatureFlag getter once the feature is fully implemented
    getProductPageRedesignContentfulFeatureFlag: (state) => state.productPageRedesignContentfulFeatureFlag,
  },
  mutations: {
    setContentfulEnvSettings: (state, payload) => {
      state.contentfulEnvSettings = payload;
    },
    // TODO: Remove setProductPageRedesignContentfulFeatureFlag mutation once the feature is fully implemented
    setProductPageRedesignContentfulFeatureFlag: (state, payload) => {
      state.productPageRedesignContentfulFeatureFlag = payload;
    },
  },
  actions: {
    fetchContentfulEnvSettings({ commit }) {
      commit('setContentfulEnvSettings', window?.envSettings || {});
      // TODO: Remove setProductPageRedesignContentfulFeatureFlag commit once the feature is fully implemented
      commit('setProductPageRedesignContentfulFeatureFlag', Boolean(window?.envSettings?.ITFE_PRODUCT_PAGE_REDESIGN));
    },
    setContentfulEnvSettings({ commit }, payload) {
      commit('setContentfulEnvSettings', payload);
    },
    // TODO: Remove setProductPageRedesignContentfulFeatureFlag action once the feature is fully implemented
    setProductPageRedesignContentfulFeatureFlag({ commit }, payload) {
      commit('setProductPageRedesignContentfulFeatureFlag', payload);
    },
  },
};
