export default {
  state: {
    partNumber: '',
    productInfoId: '',
    productLegalText: '',
    productMarketingSummary: '',
    productName: '',
    globalProductName: '',
    productVariation: '',
    shippingDisclaimer: '',
    showPartNumber: false,
    showPrice: false,
    showProductVariationName: false,
    showCompatibleDevicesTab: false,
    productHeaderDetails: '',
    productAvailability: '',
    sellable: false,
    productEmsList: [],
    filterDropdownList: [],
    pvProductEcommerceOpenText: '',
    productKickerData: {},
  },
  getters: {
    getPartNumber(state) {
      return state.partNumber;
    },
    getPvProductEcommerceOpenText(state) {
      return state.pvProductEcommerceOpenText;
    },
    getProductInfoId(state) {
      return state.productId;
    },
    getProductLegalText(state) {
      return state.productLegalText;
    },
    getShowProductLegalText(state, getters) {
      return !!getters.getProductLegalText.length;
    },
    getProductMarketingSummary(state) {
      return state.productMarketingSummary;
    },
    getShowProductMarketingSummary(state, getters) {
      return !!getters.getProductMarketingSummary.length;
    },
    getProductName(state) {
      return state.productName;
    },
    getGlobalProductName(state) {
      return state.globalProductName;
    },
    getProductVariation(state) {
      return state.productVariation;
    },
    getShippingDisclaimer(state) {
      return state.shippingDisclaimer;
    },
    getShowShippingDisclaimer(state, getters) {
      return !!getters.getShippingDisclaimer.length;
    },
    getProductHeaderDetails(state) {
      return state.productHeaderDetails;
    },
    getShowProductHeaderDetails(state, getters) {
      return !!getters.getProductHeaderDetails.length;
    },
    getShowProductName(state, getters) {
      return !!getters.getProductName;
    },
    getShowPartNumber(state) {
      return state.showPartNumber;
    },
    getShowPrice(state) {
      return state.showPrice;
    },
    getShowProductVariationName(state) {
      return state.showProductVariationName;
    },
    getShowCompatibleDevicesTab(state) {
      return state.showCompatibleDevicesTab;
    },
    getProductKickerData(state) {
      return state.productKickerData;
    },
    getProductKicker(state, getters) {
      const currentPid = getters.getProductId;
      if (!currentPid) {
        return null;
      }
      return getters.getProductKickerData[currentPid];
    },
    getProductKickerDataByPid: (state, getters) => (pid) => getters.getProductKickerData[pid],
    getShowProductKicker(state, getters) {
      return !!getters.getProductKicker;
    },
    getProductKickerContent(state, getters) {
      const productKicker = getters.getProductKicker;
      return productKicker?.kicker || '';
    },
    getProductKickerText(state, getters) {
      const productKicker = getters.getProductKicker;
      return productKicker?.text || '';
    },
    getProductAvailability(state) {
      return state.productAvailability;
    },
    getShowProductAvailability(state, getters) {
      return !!getters.getProductAvailability.length;
    },
    getSellable(state) {
      return state.sellable;
    },
    getProductEmsList(state) {
      return state.productEmsList;
    },
    getFilterDropdownList(state) {
      return state.filterDropdownList;
    },
    getFilterDropdownSelected(state) {
      // Return the item that is selected or the first one in the list if nothing is selected
      return state.filterDropdownList.find((option) => option.selected)
             || state.filterDropdownList[0]
             || {};
    },
  },
  mutations: {
    setPartNumber(state, payload) {
      state.partNumber = payload;
    },
    setProductInfoId(state, payload) {
      state.productId = payload;
    },
    setProductMarketingSummary(state, payload) {
      state.productMarketingSummary = payload || '';
    },
    setProductName(state, payload) {
      state.productName = payload;
    },
    setGlobalProductName(state, payload) {
      state.globalProductName = payload;
    },
    setProductVariation(state, payload) {
      state.productVariation = payload;
    },
    setShippingDisclaimer(state, payload) {
      state.shippingDisclaimer = payload || '';
    },
    setProductHeaderDetails(state, payload) {
      state.productHeaderDetails = payload || '';
    },
    setProductLegalText(state, payload) {
      state.productLegalText = payload || '';
    },
    setShowPartNumber(state, payload) {
      state.showPartNumber = payload;
    },
    setShowPrice(state, payload) {
      state.showPrice = payload;
    },
    setShowProductVariationName(state, payload) {
      state.showProductVariationName = payload;
    },
    setShowCompatibleDevicesTab(state, payload) {
      state.showCompatibleDevicesTab = !!payload;
    },
    setProductAvailability(state, payload) {
      state.productAvailability = payload || '';
    },
    setSellable(state, payload) {
      state.sellable = payload;
    },
    setProductEmsList(state, payload) {
      state.productEmsList = payload;
    },
    setFilterDropdownList(state, payload) {
      state.filterDropdownList = payload;
    },
    setFilterDropdownSelected(state, payload) {
      state.filterDropdownList = state.filterDropdownList.map((option) => {
        option.selected = payload === option.value;
        return option;
      });
    },
    setPvProductEcommerceOpenText(state, payload) {
      state.pvProductEcommerceOpenText = payload;
    },
    setProductKickerData(state, payload) {
      state.productKickerData = {
        ...state.productKickerData,
        [payload.productId]: {
          kicker: payload.kicker,
          text: payload.text,
        },
      };
    },
  },
  actions: {
    setProductInfo({ commit, dispatch }, payload) {
      const {
        partNumber,
        productId,
        productLegalText,
        promoBanner,
        productMarketingSummary,
        productName,
        globalProductName,
        productVariation,
        shippingDisclaimer,
        showPartNumber,
        showPrice,
        showProductVariationName,
        showCompatibleDevicesTab,
        productHeaderDetails,
        productAvailability,
        sellable,
        seo,
        pvProductEcommerceOpenText,
      } = payload;

      commit('setPartNumber', partNumber);
      commit('setProductId', productId);
      commit('setProductLegalText', productLegalText);
      commit('setProductMarketingSummary', productMarketingSummary);
      commit('setProductName', productName);
      commit('setGlobalProductName', globalProductName);
      commit('setProductVariation', productVariation);
      commit('setShippingDisclaimer', shippingDisclaimer);
      commit('setShowPartNumber', showPartNumber);
      commit('setShowPrice', showPrice);
      commit('setShowProductVariationName', showProductVariationName);
      commit('setShowCompatibleDevicesTab', showCompatibleDevicesTab);
      commit('setProductHeaderDetails', productHeaderDetails);
      commit('setProductAvailability', productAvailability);
      commit('setSellable', sellable);
      dispatch('setPromoBanner', promoBanner);
      dispatch('setSeoTitle', seo.title);
      dispatch('setPvProductEcommerceOpenText', pvProductEcommerceOpenText);
    },
    setProductOptionsList({ commit }, payload) {
      const { productEmsList, filterDropdownList } = payload;

      /*
        remove empty EMS items and EMS items that have less than 2 options
        No point in showing EMS items in the UI that only have one option to choose from
      */
      const productEmsListCleaned = productEmsList.filter((ems) => {
        if (!Object.entries(ems).length) return false;
        if (ems.options && ems.options.length === 1) return false;
        return true;
      });

      commit('setProductEmsList', productEmsListCleaned);
      commit('setFilterDropdownList', filterDropdownList);
    },
    setFilterDropdownSelected({ commit }, payload) {
      commit('setFilterDropdownSelected', payload);
    },
    setShowCompatibleDevicesTab({ commit }, payload) {
      commit('setShowCompatibleDevicesTab', payload);
    },
    setSeoTitle(context, title) {
      // use native dom parser to parse special characters in title string
      const parser = new DOMParser();
      const dom = parser.parseFromString(title, 'text/html');

      // Prevent setting the title to null if we don;t have a seo title
      if (title && dom.body.textContent) {
        document.title = dom.body.textContent;
      }
    },
    setPvProductEcommerceOpenText({ commit }, payload) {
      commit('setPvProductEcommerceOpenText', payload);
    },
    async callKickersApi({ commit, getters }) {
      const {
        getBuyGarminEndpoint,
        getLocale,
        getCountryCode,
        getProductId,
        getPriceData,
      } = getters;

      const promotionIds = getPriceData?.appliedPromotionGuids.join(',') || '';

      const url = `${getBuyGarminEndpoint}/marketing-services/products/${getProductId}/kickers?promoIds=${promotionIds}&country=${getCountryCode}&locale=${getLocale}`;

      try {
        const response = await fetch(url, {
          method: 'get',
          credentials: 'include',
        });

        // The API returns a JSON object only when a kicker is present otherwise it is blank
        const contentType = response.headers.get('content-type') || '';

        if (contentType.indexOf('application/json') === -1) {
          commit('setProductKickerData', {
            productId: getProductId,
            kicker: null,
            text: null,
          });
          return;
        }

        const kickersData = await response.json();

        commit('setProductKickerData', {
          productId: getProductId,
          kicker: kickersData?.kicker || '',
          text: kickersData?.text,
        });
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error calling kickers marketing-services');
      }
    },
    async callKlarnaApi({ dispatch, getters }) {
      const {
        getBuyGarminEndpoint,
        getLocale,
        getPriceData,
        getSellable,
        getShowPrice,
      } = getters;
      const currencyCode = window?.utag_data?.currency_code;

      // If the product is not sellable we don't want Klarna to display
      if (!getSellable || !getShowPrice) {
        dispatch('setWidgetMetadata', {});
        return;
      }

      let price = '';
      if (getPriceData?.salePrice?.price) {
        price = getPriceData?.salePrice?.price;
      } else {
        price = getPriceData?.listPrice?.price;
      }

      const url = `${getBuyGarminEndpoint}/marketing-services/widgets/klarna?price=${price}&currency=${currencyCode}&locale=${getLocale}`;

      try {
        const response = await fetch(url, {
          method: 'get',
        });

        const klarnaData = await response.json();
        if (klarnaData) {
          dispatch('setWidgetMetadata', klarnaData);
        } else {
          dispatch('setWidgetMetadata', {});
        }
      } catch (error) {
        dispatch('setWidgetMetadata', {});
        // eslint-disable-next-line
        console.error('Error calling klarna marketing-services');
      }
    },
  },
};
